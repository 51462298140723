export default function () {
  return function (amount) {
    if (amount % 10 === 1 && amount % 100 !== 11) {
      return `${amount} товар`
    } else if (
      amount % 10 >= 2 &&
      amount % 10 <= 4 &&
      (amount % 100 < 12 || amount % 100 > 14)
    ) {
      return `${amount} товари`
    } else {
      return `${amount} товарів`
    }
  }
}
